.timeline-container{

    .active,.inactive,.pending{
        position: absolute;
        top: 5px;
        left: 0%; // rtl
        bottom: 0;
        width: 2px;
        background-color: #5CAC7D;

        &::after{
            content: '';
            display: block;
            position: absolute;
            width: 18px;
            height: 18px;
            margin-top: -3px;
            margin-left: -7.5px; // rtl
            background-color: #5CAC7D;
            border-radius: 50%;
            border: 2px solid #5CAC7D;
            z-index: 1;
        }
    }

    .inactive{
        background-color: #B5B5C3;

        &::after{
            background-color: #B5B5C3;
            border: 2px solid #B5B5C3;
        }
    }
    .pending{
        background-color: #B5B5C3;
        // border: 4px solid #fff;
        &::after{
            background-color: #fff;
            border: 4px solid #5CAC7D;
        }
    }
}

.time-line-event:last-child{
    min-height: 0px !important;
    max-height: 0px !important;

    >div{
        background-color: transparent !important;
    }
}

.timeline-container-ar{
    
    .active,.inactive,.pending{
        position: absolute;
        top: 5px;
        right: 0%; // rtl
        bottom: 0;
        width: 2px;
        background-color: #5CAC7D;

        &::after{
            content: '';
            display: block;
            position: absolute;
            width: 18px;
            height: 18px;
            margin-top: -3px;
            margin-right: -7.5px; // rtl
            background-color: #5CAC7D;
            border-radius: 50%;
            border: 2px solid #5CAC7D;
            z-index: 1;
        }
    }

    .inactive{
        background-color: #B5B5C3;

        &::after{
            background-color: #B5B5C3;
            border: 2px solid #B5B5C3;
        }
    }
    .pending{
        background-color: #B5B5C3;
        // border: 4px solid #fff;
        &::after{
            background-color: #fff;
            border: 4px solid #5CAC7D;
        }
    }
}

.time-line-event:last-child{
    min-height: 0px !important;
    max-height: 0px !important;

    >div{
        background-color: transparent !important;
    }
}